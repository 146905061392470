import { ref } from 'vue'
import uploadModelFiles from '../../api/uploadModelFiles'

export default function useModel(pid) {
    const models = ref([])

    // 添加模型
    const addModel = model => {
        models.value.push(model)
        console.log(models.value)
    }

    // 移除模型
    const removeFile = model => {
        const fileIndex = models.value.findIndex(i => i.name === model.name)
        if (fileIndex >= 0) models.value.splice(fileIndex, 1)
    }

    const uploadFiles = async () => {
        const data = {
            p_id: pid.value,
            productfiles: models.value.map(i => {
                return {
                    pf_id: i.pfid,
                    pf_filename: i.name,
                    pf_path: i.pf_path,
                    pf_filesize: i.filesize,
                    pf_ext: i.pf_ext,
                    pf_uploader: '',
                    pf_createtool: i.tool1,
                    pf_ctversion: i.version1,
                    pf_subcreatetool: i.tool2,
                    pf_subctversion: i.version2,
                }
            }),
        }
        const files = await uploadModelFiles(data)
        files.forEach(i => {
            const model = models.value.find(j => j.name === i.pf_filename)
            if (model) {
                model.pfid = i.pf_id
            }
        })
        console.log('上传之后新增pfid', models.value)
    }

    return {
        models,
        addModel,
        removeFile,
        uploadFiles,
    }
}
