import { reactive } from 'vue'

export default function useDataCall(detail, models, modelPhotos, modelDetail, photoCover) {
    console.log(detail)
    console.log(models)
    console.log(modelPhotos)
    console.log(modelDetail)

    // 回显模型文件
    const { files } = detail
    files.forEach(file => {
        models.value.push(
            reactive({
                name: file.pf_filename,
                pfid: file.pf_id,
                time: file.ctime,
                file: file,
                tool1: file.pf_createtool,
                tool2: file.pf_subcreatetool,
                progress: 100,
                version1: file.pf_ctversion,
                version2: file.pf_subctversion,
                pf_path: file.pf_path,
                filesize: file.pf_filesize,
                pf_ext: file.pf_ext,
            })
        )
    })
    // 回显相册
    const { photos } = detail
    photos.forEach(photo => {
        if (photo.cover === 1) {
            photoCover.value = photo.fullpath
        }
        modelPhotos.push(
            reactive({
                pp_id: photo.pp_id,
                pp_filename: photo.pp_filename,
                src: photo.fullpath,
                name: photo.pp_filename,
                pp_path: photo.pp_path,
                cover: photo.cover,
                progress: 100,
            })
        )
    })

    // 回显基础信息
    modelDetail.p_name = detail.p_name
    modelDetail.p_intro = detail.p_intro
    modelDetail.p_cate_1 = detail.p_cate_1
    modelDetail.p_cate_2 = detail.p_cate_2
    modelDetail.p_price = detail.p_price
    modelDetail.p_tags = detail.p_tags.split(' ')
    modelDetail.p_lictype = detail.p_lictype
    modelDetail.pm_geometry = detail.pm_geometry
    modelDetail.pm_mash = detail.pm_mash
    modelDetail.pm_vertices = detail.pm_vertices
    modelDetail.pm_istexture = detail.pm_istexture
    modelDetail.pm_ismaterials = detail.pm_ismaterials
    modelDetail.pm_isanimation = detail.pm_isanimation
    modelDetail.pm_isrigged = detail.pm_isrigged
    modelDetail.pm_isuvlayout = detail.pm_isuvlayout
}
