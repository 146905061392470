import { computed, onBeforeMount, ref } from 'vue'
import getTools from '../../api/getTools'

export default function useTools() {
    let tools = ref([])

    const formatData = data => {
        return data.map(j => {
            const d = { value: j.pct_id, label: j.pct_name, child: j.subtools }
            if (d.child) d.child = formatData(d.child)
            return d
        })
    }

    onBeforeMount(async () => {
        const data = await getTools()
        tools.value = formatData(data)
    })
    // 判断是否显示tool2
    const isShowTool2 = computed(() => {
        return model => {
            const tool1Item = tools.value.find(i => {
                return i.value === model.tool1
            })
            return tool1Item && tool1Item.child ? tool1Item.child : []
        }
    })
    return {
        tools,
        isShowTool2,
    }
}
