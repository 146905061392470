import { computed, ref } from 'vue'
import saveModel from '../../api/saveModel'
import { useMessage } from 'naive-ui'

export default function tab(models, tools, modelDetail, modelPhotos, uploadFiles, uploadPhotoToServer) {
    const tabs = [1, 2]
    let activeTab = ref(tabs[0])
    let busy = ref(false)
    const message = useMessage()
    // 检查当前模型是否选择了tool2
    const isShowTool2 = model => {
        if (!tools) return false
        const tool1Item = tools.value.find(i => {
            return i.value === model.tool1
        })
        return !!(tool1Item && tool1Item.child)
    }
    // 下一步按钮是否禁止
    const enbleNextBtn = computed(() => {
        if (!models.value.length > 0) return false
        return models.value.every(i => {
            if (!isShowTool2(i)) {
                return i.progress === 100 && i.file && i.tool1 && i.version1
            } else {
                return i.progress === 100 && i.file && i.tool1 && i.version1 && i.tool2 && i.version2
            }
        })
    })

    // 完成按钮是否禁用
    let enbleFinishedBtn = computed(() => {
        if (modelPhotos.length <= 0) return false
        // 分享
        const modelDetailCp = Object.assign({}, modelDetail)
        if (modelDetailCp.p_lictype === 0) {
            delete modelDetailCp.p_price
        }

        return Object.values(modelDetailCp).every(i => {
            return i !== null && i !== ''
        })
    })

    // 完成模型最后步骤的逻辑处理
    const finished = async () => {
        busy.value = true
        // 上传图片信息
        uploadPhotoToServer()

        // 保存模型
        const data = {
            ...modelDetail,
            p_id: modelDetail.p_id,
            p_tags: modelDetail.p_tags.join(' '),
        }
        const res = await saveModel(data)
        if (res) {
            message.success('上传成功！')
        }
        busy.value = false
    }

    // 是否显示完成按钮
    const showFinished = computed(() => {
        return tabs.findIndex(i => i === activeTab.value) === tabs.length - 1
    })

    // 是否显示上一步按钮
    const showPrev = computed(() => {
        return tabs.findIndex(i => i === activeTab.value) !== 0
    })

    // 是否显示下一步按钮
    const showNext = computed(() => {
        return tabs.findIndex(i => i === activeTab.value) !== tabs.length - 1
    })

    // 下一步逻辑
    const nextStep = () => {
        const currentTabIndex = tabs.findIndex(i => i === activeTab.value)
        activeTab.value = tabs[(currentTabIndex + 1) % tabs.length]

        uploadFiles()
    }

    // 上一步逻辑
    const prevStep = () => {
        const currentTabIndex = tabs.findIndex(i => i === activeTab.value)
        activeTab.value = tabs[(currentTabIndex - 1) % tabs.length]
    }

    return {
        tabs,
        activeTab,
        enbleNextBtn,
        nextStep,
        prevStep,
        showPrev,
        showNext,
        showFinished,
        finished,
        enbleFinishedBtn,
        busy,
    }
}
