import {
    // create naive ui
    create,
    // component
    NButton,
    NTabs,
    NTabPane,
    NIcon,
    NProgress,
    NSelect,
    NInput,
    NInputNumber,
    NRadioGroup,
    NRadio,
    NSpace,
    NDynamicTags,
    NForm,
    NFormItem,
    NDivider,
    NMessageProvider,
    NSpin,
} from 'naive-ui'

export default create({
    components: [NButton, NTabs, NTabPane, NIcon, NProgress, NSelect, NInput, NRadioGroup, NRadio, NSpace, NDynamicTags, NForm, NFormItem, NDivider, NInputNumber, NMessageProvider, NSpin],
})
