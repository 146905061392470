import { onBeforeMount, reactive, ref } from 'vue'
import uploadPhoto from '../../utils/uploadPhoto'
import uploadPhotoFiles from '../../api/uploadPhotoFiles'
import getCate from '../../api/getCate'

export default function useModelDetail(pid, photoCover) {
    const modelDetail = reactive({
        p_id: pid, //	产品ID
        // p_creater: '', //创建者
        p_name: '', //模型产品名称
        p_cate_1: '', //	分类一
        p_cate_2: '', //	否		分类二
        p_intro: '', //	否		描述
        p_tags: [], //	办公文具 文具 办公用品 计时工具 笔筒 黑色笔筒 金属笔筒 笔	标签
        p_lictype: 0, //	否		模型类型 0模型分享 1出售模型
        p_price: '', //	否		价格
        // p_vprice: '', //	否		积分
        pm_geometry: 1, //	否		几何体类型 1:Polygonal 2:NURBS 3:Subdivision 4:Polygonal Quads/Tris 5:Polygonal Quads only 6:Polygonal Tris only 7:Polygonal Ngons used 8:Unknown
        pm_mash: 0, //	否		多边形面数
        pm_vertices: 0, //	否		顶点数
        pm_istexture: 1, //	否		包含贴图(0否 1是)
        pm_ismaterials: 1, //	否		包含材质(0否 1是)
        pm_isanimation: 1, //	否		包含动画(0否 1是)
        pm_isrigged: 0, //	否		包含绑定(0否 1是)
        pm_isuvlayout: 0, //	否		UV展开(0否 1是)
    })

    const modelPhotos = reactive([])

    const photoLabel = ref(null)

    const cate1Options = ref([])

    const cate2Options = ref([])
    //1:Polygonal 2:NURBS 3:Subdivision 4:Polygonal Quads/Tris 5:Polygonal Quads only 6:Polygonal Tris only 7:Polygonal Ngons used 8:Unknown
    const pm_geometryOp = [
        {
            value: 1,
            label: 'Polygonal',
        },
        {
            value: 2,
            label: 'NURBS',
        },
        {
            value: 3,
            label: 'Subdivision',
        },
        {
            value: 4,
            label: 'Polygonal Quads/Tris',
        },
        {
            value: 5,
            label: 'Polygonal Quads only',
        },
        {
            value: 6,
            label: 'Polygonal Tris only',
        },
        {
            value: 7,
            label: 'Polygonal Ngons used',
        },
        {
            value: 8,
            label: 'Unknown',
        },
    ]

    const trigglePhotoInput = () => {
        photoLabel.value.click()
    }

    const handlePhotoCoverChange = e => {
        photoCover.value = e.target.value
        modelPhotos.forEach(i => {
            if (i.src === photoCover.value) {
                i.cover = 1
            }
        })
    }

    const removePhotoCover = p => {
        const photoIndex = modelPhotos.findIndex(i => i.src === p.src)
        if (photoIndex >= 0) {
            modelPhotos.splice(photoIndex, 1)
        }
    }

    const photoChange = event => {
        const files = event.target.files
        files.forEach(file => {
            const photo = reactive({
                pp_id: null,
                pp_filename: file.name,
                src: URL.createObjectURL(file),
                name: file.name,
                pp_path: '',
                cover: 0,
                progress: 0,
            })
            modelPhotos.push(photo)
            // addModel(model)
            uploadPhoto(file, pid.value, photo)
        })

        event.target.value = ''
    }

    const uploadPhotoToServer = async () => {
        const data = {
            p_id: pid.value,
            productphotos: modelPhotos,
        }
        const photos = await uploadPhotoFiles(data)
        // todo 赋值图片id给modelPhotos
        photos.forEach(i => {
            const photo = modelPhotos.find(j => j.pp_filename === i.pp_filename)
            if (photo) {
                photo.pp_id = i.pp_id
            }
        })
    }

    onBeforeMount(() => {
        // getModelCate1()
        // getModelCate2()
    })

    // 获取详情分类1
    const getModelCate1 = async () => {
        const { list } = await getCate(0)
        cate1Options.value = list.map(i => {
            return {
                value: Number(i.pc_id),
                label: i.pc_name.replace(/&nbsp;/g, '-'),
            }
        })
    }
    getModelCate1()

    // 获取详情分类1
    const getModelCate2 = async () => {
        const { list } = await getCate(1)
        cate2Options.value = list.map(i => {
            return {
                value: Number(i.pc_id),
                label: i.pc_name.replace(/&nbsp;/g, '-'),
            }
        })
    }
    getModelCate2()
    
    return {
        modelDetail,
        modelPhotos,
        photoLabel,
        trigglePhotoInput,
        photoChange,
        photoCover,
        handlePhotoCoverChange,
        removePhotoCover,
        cate1Options,
        cate2Options,
        pm_geometryOp,
        uploadPhotoToServer,
    }
}
