<template>
    <div id="upload">
        <n-spin :show="busy">
            <div class="upload_container">
                <div class="bar">
                    <span class="left">编辑模型{{ pid }}</span>
                    <div class="right">
                        <n-button type="primary" color="#1c64a1" v-show="showPrev" @click="prevStep" style="margin-right: 20px">上一步 </n-button>
                        <n-button type="primary" color="#1c64a1" :disabled="!enbleNextBtn" @click="nextStep" v-show="showNext" style="margin-right: 20px">下一步 </n-button>
                        <n-button type="primary" color="#f18e23" :disabled="!enbleFinishedBtn" @click="finished" v-show="showFinished" style="margin-right: 20px">完成 </n-button>
                        <n-button class="cancel_btn" text color="#1c64a1">取消</n-button>
                    </div>
                </div>
                <div class="step">
                    <n-tabs
                        v-model:value="activeTab"
                        size="large"
                        :tab-style="{
                            borderRadius: 0,
                            width: '300px',
                            height: '50px',
                            padding: '0 30px',
                            background: '#eee',
                        }"
                    >
                        <n-tab-pane display-directive="show" tab="1.上传3D模型" :name="tabs[0]" :disabled="true">
                            <div class="pane_container">
                                <div class="file_container">
                                    <div class="file-item" v-for="(item, index) in models" :key="index">
                                        <div class="name">
                                            <div>
                                                <n-icon size="22">
                                                    <AllInboxSharp></AllInboxSharp>
                                                </n-icon>
                                                <span>{{ item.name }}</span>
                                            </div>
                                            <n-button text color="red" @click="removeFile(item)">
                                                <n-icon size="20">
                                                    <CloseFilled></CloseFilled>
                                                </n-icon>
                                            </n-button>
                                        </div>
                                        <div class="time">
                                            <div>{{ item.time }}</div>
                                        </div>
                                        <div class="tools">
                                            <div style="display: flex; align-items: center">
                                                <n-select v-model:value="item.tool1" :options="tools" :fallback-option="false" class="tool1" placeholder="请选择创作工具" />
                                                <div style="width: 80px; margin-left: 40px; display: flex; flex: none; align-items: center">
                                                    <span style="flex: none; margin-right: 6px">版本</span>
                                                    <n-input v-model:value="item.version1" type="input" placeholder="" />
                                                </div>
                                            </div>
                                            <div style="display: flex; align-items: center" v-if="isShowTool2(item).length > 0">
                                                <n-select class="tool2" v-model:value="item.tool2" :fallback-option="false" placeholder="请选择创作工具" :options="isShowTool2(item)" />
                                                <div style="width: 80px; margin-left: 40px; display: flex; flex: none; align-items: center">
                                                    <span style="flex: none; margin-right: 6px">版本</span>
                                                    <n-input v-model:value="item.version2" type="input" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="progress">
                                            <n-progress
                                                type="line"
                                                color="#19598f"
                                                :status="item.progress === 100 ? 'success' : 'default'"
                                                :percentage="item.progress"
                                                :height="10"
                                                processing
                                            ></n-progress>
                                        </div>
                                    </div>
                                </div>
                                <div class="control_container">
                                    <input type="file" multiple id="fileInput" style="display: none" accept=".zip,.rar,.7z" @change="fileChange" />
                                    <label for="fileInput" ref="fileLabel"></label>
                                    <n-button color="#dd4b4b" style="width: 260px; height: 80px" @click.stop="triggleFileInput">
                                        <n-icon size="60">
                                            <CloudUploadFilled></CloudUploadFilled>
                                        </n-icon>
                                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start; align-self: stretch; margin-left: 10px">
                                            <span style="font-size: 20px">添加3D模型</span>
                                            <span style="margin-top: 10px">上传格式支持zip rar 7z</span>
                                        </div>
                                    </n-button>

                                    <div class="attention">
                                        <h4>注意事项</h4>
                                        <p>支持zip、rar、7z格式。请将贴图、文件一同打包在压缩包内。如果您可以提供多种模型格式，请分开打包。</p>
                                    </div>
                                </div>
                            </div>
                        </n-tab-pane>
                        <n-tab-pane display-directive="show" tab="2.添加模型详情" :name="tabs[1]" :disabled="true">
                            <div class="model_detail">
                                <div class="left" style="text-align: left">
                                    <div>模型名称</div>
                                    <div style="margin-top: 15px">
                                        <n-input v-model:value="modelDetail.p_name" type="input" placeholder="" />
                                    </div>

                                    <div class="photo_container">
                                        <div>模型效果图</div>
                                        <div class="photo_list" style="margin-top: 15px">
                                            <div v-for="(photo, index) in modelPhotos" :key="index" class="photo">
                                                <img :src="photo.src" alt="" />
                                                <n-radio :checked="photoCover === photo.src" @change="handlePhotoCoverChange" :value="photo.src" name="cover"> 设为封面 </n-radio>
                                                <n-button @click="removePhotoCover(photo)" color="red" text style="font-size: 24px; position: absolute; top: 2px; right: 2px">
                                                    <n-icon>
                                                        <CloseFilled></CloseFilled>
                                                    </n-icon>
                                                </n-button>
                                            </div>
                                        </div>

                                        <input type="file" id="photoInput" multiple style="display: none" accept="image/*" @change="photoChange" />
                                        <label for="photoInput" ref="photoLabel"></label>
                                        <n-button color="#5c5c5c" @click.stop="trigglePhotoInput">选择效果图</n-button>
                                    </div>
                                    <div class="cate">
                                        <div>选择分类</div>
                                        <div>
                                            <n-select v-model:value="modelDetail.p_cate_1" :options="cate1Options" placeholder="模型分类" />
                                        </div>
                                        <div>
                                            <n-select v-model:value="modelDetail.p_cate_2" :options="cate2Options" placeholder="模型分类" />
                                        </div>
                                    </div>
                                    <div class="des" style="margin-top: 20px">
                                        <div>描述</div>
                                        <div style="margin-top: 15px">
                                            <n-input v-model:value="modelDetail.p_intro" type="textarea" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="tags" style="margin-top: 20px">
                                        <div>标签</div>
                                        <div style="margin-top: 20px">
                                            <n-dynamic-tags v-model:value="modelDetail.p_tags" />
                                        </div>
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="attention">
                                        <n-radio-group v-model:value="modelDetail.p_lictype" name="p_lictype">
                                            <n-space>
                                                <n-radio :value="0"> 模型分享</n-radio>
                                                <n-radio :value="1"> 模型出售</n-radio>
                                            </n-space>
                                        </n-radio-group>
                                        <div style="margin-top: 20px">
                                            <p v-if="modelDetail.p_lictype === 1" style="color: #757372; font-size: 12px; display: flex; align-items: center">
                                                <input type="checkbox" checked style="margin-right: 5px" />
                                                已阅读3D城 <span style="color: #f00">出售协议</span>，了解 <span style="color: #f00">出售模型规范</span>
                                            </p>
                                            <p v-if="modelDetail.p_lictype === 0" style="color: #757372; font-size: 12px; display: flex; align-items: center">
                                                <input type="checkbox" checked style="margin-right: 5px" />
                                                已阅读3D城 <span style="color: #f00">版权声明</span>，了解 <span style="color: #f00">共享模型规范</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="setting" style="text-align: left; margin-top: 20px; padding: 15px; border: 1px solid #ccc">
                                        <n-form label-placement="left" label-align="left" :label-width="80" size="small">
                                            <div style="font-size: 14px; font-weight: 700">发布设定</div>
                                            <n-divider />
                                            <n-form-item v-if="modelDetail.p_lictype === 1" label="价格(¥)" :label-style="{ textAlign: 'left' }">
                                                <n-input placeholder="" v-model:value="modelDetail.p_price" />
                                            </n-form-item>
                                            <p v-if="modelDetail.p_lictype === 0" style="margin-bottom: 30px">模型分享可以获得一定的积分</p>
                                            <div style="font-size: 14px; font-weight: 700">模型基本参数</div>
                                            <n-divider />
                                            <n-form-item label="几何体类型" :label-style="{ textAlign: 'left' }">
                                                <n-select placeholder="" :options="pm_geometryOp" v-model:value="modelDetail.pm_geometry" />
                                            </n-form-item>
                                            <n-form-item label="多边形面数" :label-style="{ textAlign: 'left' }">
                                                <n-input-number placeholder="" v-model:value="modelDetail.pm_mash" />
                                            </n-form-item>
                                            <n-form-item label="顶点数" :label-style="{ textAlign: 'left' }">
                                                <n-input-number placeholder="" v-model:value="modelDetail.pm_vertices" />
                                            </n-form-item>
                                            <n-form-item label="包含贴图" :label-style="{ textAlign: 'left' }">
                                                <n-radio-group v-model:value="modelDetail.pm_istexture" name="pm_istexture">
                                                    <n-space>
                                                        <n-radio :value="1">是</n-radio>
                                                        <n-radio :value="0">否</n-radio>
                                                    </n-space>
                                                </n-radio-group>
                                            </n-form-item>
                                            <n-form-item label="包含材质" :label-style="{ textAlign: 'left' }">
                                                <n-radio-group v-model:value="modelDetail.pm_ismaterials" name="pm_ismaterials">
                                                    <n-space>
                                                        <n-radio :value="1">是</n-radio>
                                                        <n-radio :value="0">否</n-radio>
                                                    </n-space>
                                                </n-radio-group>
                                            </n-form-item>
                                            <n-form-item label="包含动画" :label-style="{ textAlign: 'left' }">
                                                <n-radio-group v-model:value="modelDetail.pm_isanimation" name="pm_isanimation">
                                                    <n-space>
                                                        <n-radio :value="1">是</n-radio>
                                                        <n-radio :value="0">否</n-radio>
                                                    </n-space>
                                                </n-radio-group>
                                            </n-form-item>
                                            <n-form-item label="包含绑定" :label-style="{ textAlign: 'left' }">
                                                <n-radio-group v-model:value="modelDetail.pm_isrigged" name="pm_isrigged">
                                                    <n-space>
                                                        <n-radio :value="1">是</n-radio>
                                                        <n-radio :value="0">否</n-radio>
                                                    </n-space>
                                                </n-radio-group>
                                            </n-form-item>
                                            <n-form-item label="UV展开" :label-style="{ textAlign: 'left' }">
                                                <n-radio-group v-model:value="modelDetail.pm_isuvlayout" name="pm_isuvlayout">
                                                    <n-space>
                                                        <n-radio :value="1">是</n-radio>
                                                        <n-radio :value="0">否</n-radio>
                                                    </n-space>
                                                </n-radio-group>
                                            </n-form-item>
                                        </n-form>
                                    </div>
                                </div>
                            </div>
                        </n-tab-pane>
                    </n-tabs>
                </div>
            </div>
        </n-spin>
    </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue'
import { CloudUploadFilled, AllInboxSharp, CloseFilled } from '@vicons/material'
import useModel from '../composables/upload/useModel'
import useTools from '../composables/upload/useTools'
import useFileChange from '../composables/upload/useFileChange'
import useTab from '../composables/upload/useTab'
import useModelDetail from '../composables/upload/useModelDetail'
import { useThemeVars } from 'naive-ui'
import generatePid from '../api/generatePid'
import getProDetail from '../api/getProDetail'
import useDataCall from '../composables/upload/useDataCall'

export default defineComponent({
    name: 'Upload',
    components: {
        CloudUploadFilled,
        AllInboxSharp,
        CloseFilled,
    },
    setup() {
        let pid = ref('')
        let photoCover = ref('')
        // 获取pid
        onBeforeMount(async () => {
            const url = new URL(location.href)
            pid.value = url.searchParams.get('pid')
            if (!pid.value) {
                const data = await generatePid()
                pid.value = data.p_id
            } else {
                const detail = await getProDetail(pid.value)
                useDataCall(detail, models, modelPhotos, modelDetail, photoCover)
            }
        })

        const { tools, isShowTool2 } = useTools()

        const { models, addModel, removeFile, uploadFiles } = useModel(pid, tools)

        const { fileLabel, fileChange, triggleFileInput } = useFileChange(models, pid)

        const { modelDetail, modelPhotos, photoChange, trigglePhotoInput, photoLabel, handlePhotoCoverChange, removePhotoCover, cate1Options, cate2Options, pm_geometryOp, uploadPhotoToServer } =
            useModelDetail(pid, photoCover)

        const { tabs, activeTab, enbleNextBtn, enbleFinishedBtn, finished, nextStep, prevStep, showNext, showPrev, showFinished, busy } = useTab(
            models,
            tools,
            modelDetail,
            modelPhotos,
            uploadFiles,
            uploadPhotoToServer
        )

        return {
            models,
            tools,
            tabs,
            activeTab,
            pid,
            isShowTool2,
            removeFile,
            addModel,
            nextStep,
            prevStep,
            finished,
            showNext,
            showPrev,
            showFinished,
            enbleNextBtn,
            enbleFinishedBtn,
            fileLabel,
            uploadFiles,
            fileChange,
            triggleFileInput,
            busy,
            themeVars: useThemeVars(),
            modelDetail,
            modelPhotos,
            photoChange,
            trigglePhotoInput,
            photoLabel,
            photoCover,
            handlePhotoCoverChange,
            removePhotoCover,
            cate1Options,
            cate2Options,
            pm_geometryOp,
        }
    },
})
</script>
<style lang="stylus" scoped>
bar_height = 72px
#upload
    padding 60px 0

    .upload_container
        margin: 0 auto
        background #fff
        border 1px solid #ccc
        box-shadow 0 0 5px #ddd
        border-radius 8px
        width 960px
        min-height 650px
        overflow hidden
        display flex
        flex-direction column

        .bar
            background #fafafa
            border-bottom 1px solid #ddd
            height bar_height
            display flex
            padding 0 30px
            justify-content space-between
            align-items center
            text-shadow 0 0 2px #eee

            .left
                font-size 20px

        .step
            flex 1
            color #757372
            border-bottom 1px solid #ddd
            overflow auto
            width 100%

            .pane_container
                width 100%
                height 100%
                display flex
                justify-content space-between
                padding 50px 30px
                box-sizing border-box

                .file_container
                    width 600px
                    height 600px
                    overflow-y auto
                    overflow-x hidden
                    border 1px solid #ccc
                    padding 5px

                    .file-item
                        width 100%
                        box-sizing border-box
                        border 1px solid gray
                        color #999
                        padding 15px 20px
                        margin-bottom 15px

                        .name
                            display flex
                            justify-content space-between
                            align-items center

                            & > div:first-child
                                display flex
                                align-items center

                                & > span
                                    margin-left 10px


                        .time
                            text-align left
                            margin-top 10px

                        .progress
                            margin-top 20px

                        .tools
                            margin-top 20px

                            .tool2
                                margin-top 5px

                .control_container
                    width 260px
                    align-self flex-start


                    .attention
                        text-align left
                        margin-top 50px
                        color #757372

                        h4
                            color #757372
                            margin-bottom 0

                        p
                            color #757372
                            font-size 12px
                            line-height 26px


            .model_detail
                @extends .pane_container
                min-height 600px
                padding 0 30px 30px 30px
                overflow: hidden

                .left
                    flex 2 0 0
                    overflow-x hidden
                    padding-bottom 150px

                    .photo_container
                        width 100%
                        margin-top 30px

                        .photo_list
                            width 100%
                            height 460px
                            overflow-y auto
                            border 1px solid #ddd
                            margin-bottom 15px
                            box-sizing border-box
                            display flex
                            flex-wrap wrap
                            justify-content space-between
                            align-content: flex-start

                            .photo
                                width 240px
                                height 100px
                                margin 10px
                                box-shadow 1px 1px 6px 0px;
                                display flex
                                justify-content space-between
                                position relative

                                img
                                    width 100px


                    //border 1px solid gold

                    .cate
                        margin-top 50px

                        & > div
                            margin-bottom 15px

                .right
                    flex 1 0 0
                    margin-left 30px
                    overflow-x hidden

                    .attention
                        width 100%
                        height 130px
                        box-sizing border-box
                        background: #FFEEC8
                        padding: 5px 15px
                        border: 1px solid #FFE4A6
                        display flex
                        flex-direction column
                        align-items center
                        justify-content center
</style>
<style scoped lang="stylus">
:deep( .n-tabs-nav)
    background #eee

:deep(.n-tabs-tab:not(.n-tabs-tab--active,.n-tabs-tab--disabled):hover)
    color black

:deep(.n-tabs-tab--active)
    color #1c64a1 !important
    background #fff !important

:deep(.n-tabs-bar)
    height 0

:deep(.n-tabs-tab-pad)
    width 0
</style>
