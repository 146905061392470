// 把华为云sdk变量存进全局变量中,文件在index.html中静态引入
let ObsClient = window.ObsClient

export default function uploadPhoto(file, pid, photo) {
    // 创建ObsClient实例
    const obsClient = new ObsClient({
        access_key_id: 'L9OTDUYJLPBUS7HPOKRH',
        secret_access_key: '6u1ywTa6lUTsshCEHPkYStjdQ47uwzQVRkwkGWOF',
        server: 'https://obs.cn-east-2.myhuaweicloud.com',
    })

    let cp
    let hook
    const path = `upload_new/${pid}/photos/o/`
    obsClient.uploadFile(
        {
            Bucket: 'city',
            Key: path + photo.name,
            SourceFile: file,
            PartSize: 900 * 1024 * 1024 * 5,
            ProgressCallback: function (transferredAmount, totalAmount) {
                // console.log(
                //     'step1',
                //     (transferredAmount * 1.0) / totalSeconds / 1024
                // )
                // console.log('step1', (transferredAmount * 100.0) / totalAmount)
                photo.progress = Number(((transferredAmount * 100.0) / totalAmount).toFixed(2))
                if (hook && transferredAmount / totalAmount > 0.5) {
                    // 暂停断点续传任务
                    hook.cancel()
                }
            },
            ResumeCallback: function (resumeHook, uploadCheckpoint) {
                // 获取取消断点续传上传任务控制参数
                hook = resumeHook
                // 记录断点
                cp = uploadCheckpoint
            },
        },
        function (err, result) {
            console.error('Error-->' + err)
            // 出现错误，再次调用断点续传接口，继续上传任务
            if (err) {
                obsClient.uploadFile(
                    {
                        UploadCheckpoint: cp,
                        ProgressCallback: function (transferredAmount, totalAmount) {
                            // console.log(
                            //     'step2',
                            //     (transferredAmount * 1.0) / totalSeconds / 1024
                            // )
                            // console.log(
                            //     'step2',
                            //     (transferredAmount * 100.0) / totalAmount
                            // )
                            photo.progress = Number(((transferredAmount * 100.0) / totalAmount).toFixed(2))
                        },
                    },
                    function (err, result) {
                        if (err) {
                            console.error('Error-->' + err)
                        } else {
                            if (result.CommonMsg.Status < 300) {
                                console.log('RequestId-->' + result.InterfaceResult.RequestId)
                                console.log('Bucket-->' + result.InterfaceResult.Bucket)
                                console.log('Key-->' + result.InterfaceResult.Key)
                                console.log('Location-->' + result.InterfaceResult.Location)
                                setTimeout(() => {
                                    photo.progress = 100
                                    let path = result.InterfaceResult.Key
                                    path = path.slice(0, path.lastIndexOf('/o') + 1)
                                    photo.pp_path = '/' + path
                                    console.log(photo.pp_path)
                                }, 1000)
                            } else {
                                console.log('Code-->' + result.CommonMsg.Code)
                                console.log('Message-->' + result.CommonMsg.Message)
                            }
                        }
                    }
                )
            } else {
                console.log('Status-->' + result.CommonMsg.Status)
                if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
                    setTimeout(() => {
                        photo.progress = 100
                        let path = result.InterfaceResult.Key
                        path = path.slice(0, path.lastIndexOf('/o') + 1)
                        photo.pp_path = '/' + path
                        console.log(photo.pp_path)
                    }, 1000)
                    console.log('RequestId-->' + result.InterfaceResult.RequestId)
                }
            }
        }
    )
}
