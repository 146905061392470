import { ref, reactive } from 'vue'
import uploadModel from '../../utils/uploadModel'
// import useModel from './useModel'
export default function useFileChange(models, pid) {
    const fileLabel = ref(null)
    // const { addModel } = useModel()
    const triggleFileInput = () => {
        fileLabel.value.click()
    }
    const fileChange = event => {
        const files = event.target.files
        files.forEach(file => {
            const model = reactive({
                name: file.name,
                pfid: null,
                time: file.lastModifiedDate.toLocaleString(),
                file: file,
                tool1: '',
                tool2: '',
                progress: 0,
                version1: '',
                version2: '',
                pf_path: '',
                filesize: file.size,
                pf_ext: file.name.split('.')[1],
            })
            models.value.push(model)
            // addModel(model)
            uploadModel(file, pid.value, model)
        })

        event.target.value = ''
    }

    return {
        fileLabel,
        fileChange,
        triggleFileInput,
    }
}
