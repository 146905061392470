import axios from 'axios'

let accessToken = {
    token: '',
    exp: '',
    utoken: '',
}

function expire() {
    const times = 1000 * 60 * 60 * 12
    return new Date().getTime() - accessToken.exp >= times
}

export default () => {
    return new Promise(resolve => {
        const url = new URL(location.href)
        const utoken = url.searchParams.get('utoken')
        // 不同用户
        if (!accessToken.utoken || utoken !== accessToken.utoken) {
            axios
                .get('/mapi/tdfusers/utokenlogin', {
                    params: { utoken: utoken },
                })
                .then(res => {
                    accessToken.token = res.data.token
                    accessToken.exp = new Date().getTime()
                    accessToken.utoken = utoken
                    resolve(res.data.token)
                })
        } else {
            // 同一用户是否过期
            if (accessToken.token && !expire()) {
                console.log('同用户为过期', utoken)
                resolve(accessToken.token)
            } else {
                // 过期从新获取
                console.log('同用户过期', utoken)
                axios
                    .get('/mapi/tdfusers/utokenlogin', {
                        params: { utoken: utoken },
                    })
                    .then(res => {
                        accessToken.token = res.data.token
                        accessToken.exp = new Date().getTime()
                        accessToken.utoken = utoken
                        resolve(res.data.token)
                    })
            }
        }
    })
}
