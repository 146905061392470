import axios from 'axios'
import getToken from '../api/getToken'

axios.defaults.baseURL = process.env.VUE_APP_BASE_API

const _axios = axios.create()

_axios.interceptors.request.use(async config => {
    if (config.url.indexOf('utokenlogin') < 0) {
        config.headers['Authorization'] = await getToken()
    }
    return config
})
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        if (response.status === 200) {
            return response.data.data
        } else {
            return response
        }
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error)
    }
)

export default _axios
